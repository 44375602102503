@import '../Utils/mixins';
@import '../Utils/vars';

body {
    background: url('../../../img/logo-eni-without-text.svg') no-repeat 55vw 60vh $grey-light;
    background-size: 100vh auto;
}


.login-form {
    margin: 0 auto;
    max-width: 360px;
    text-align: center;

    #submit-login {
        background-color: $blue-eni;
        color: $white;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: $break-lg) {


}

@media (min-width: $break-lg) and (max-height: 800px) {
}
